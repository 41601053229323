import * as tslib_1 from "tslib";
import { Events, NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { convertSnaps } from '../db-utils';
import { Router } from '@angular/router';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import * as firebase from 'firebase';
import { encodeURL } from '../parseURL';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import { VendorService } from '../vendor/vendor.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../label/label.service";
import * as i4 from "../shared/shared.service";
import * as i5 from "@angular/router";
import * as i6 from "../utils/utils.service";
import * as i7 from "../loggly-logger/loggly-logger.service";
import * as i8 from "@angular/fire/storage";
import * as i9 from "@ionic/storage";
import * as i10 from "../vendor/vendor.service";
var WidgetsService = /** @class */ (function () {
    function WidgetsService(events, angularFirestore, labelService, sharedService, router, navCtrl, utilsService, logglyService, angularFireStorage, storage, vendorService) {
        this.events = events;
        this.angularFirestore = angularFirestore;
        this.labelService = labelService;
        this.sharedService = sharedService;
        this.router = router;
        this.navCtrl = navCtrl;
        this.utilsService = utilsService;
        this.logglyService = logglyService;
        this.angularFireStorage = angularFireStorage;
        this.storage = storage;
        this.vendorService = vendorService;
        this.widgetRefrence = this.angularFirestore.collection('widgets');
    }
    WidgetsService.prototype.initializeSubscriptions = function () { };
    WidgetsService.prototype.getCustomPageSections = function (pageId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var pageData, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.getSections(pageId)];
                                case 1:
                                    pageData = _a.sent();
                                    resolve(pageData);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_1 = _a.sent();
                                    console.dir(error_1);
                                    error_1['location'] = 'widgets-service:getCustomPageSections';
                                    this.logglyService.log(error_1);
                                    resolve({ pageName: '', sections: [] });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.getSections = function (pageId) {
        if (pageId === void 0) { pageId = 'homepage'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appSections_1, sectionsDoc, error_2;
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        appSections_1 = [];
                        return [4 /*yield*/, this.angularFirestore.collection('pages').doc(pageId).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        sectionsDoc = _a.sent();
                        if (sectionsDoc) {
                            if (sectionsDoc.sections && sectionsDoc.sections.length) {
                                sectionsDoc.sections.forEach(function (section) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                                    var regionId, sectionRegionIds, sectionAvailable;
                                    return tslib_1.__generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                                            case 1:
                                                regionId = (_a.sent()).regionId;
                                                sectionRegionIds = 'regionId' in section ? section.regionId : [];
                                                sectionAvailable = false;
                                                if (section.location === 'web' || section.location === 'all') {
                                                    if (regionId) {
                                                        if (sectionRegionIds.includes(regionId)) {
                                                            sectionAvailable = true;
                                                        }
                                                    }
                                                    else {
                                                        sectionAvailable = true;
                                                    }
                                                }
                                                if (sectionAvailable) {
                                                    appSections_1.push(section);
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }
                        }
                        return [2 /*return*/, pageId === 'homepage' ? appSections_1 : { pageName: sectionsDoc.name || '', sections: appSections_1 }];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2['location'] = 'widgets-service:getSections';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /************** Product Carousel*********************/
    WidgetsService.prototype.getProductsForCarousel = function (widgetID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var returnData, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.widgetRefrence.doc(widgetID).collection('products', function (ref) { return ref.orderBy('sortedAt', 'desc').where('data.status', '==', true); }).snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        if (data && data.length) {
                            returnData = data;
                            return [2 /*return*/, returnData];
                        }
                        else {
                            returnData = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WidgetsService.prototype.getProductsForList = function (widgetID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, productsData, index, obj, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.widgetRefrence.doc(widgetID).collection('products', function (ref) { return ref.orderBy('sortedAt', 'desc').where('data.status', '==', true); }).snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 1:
                        data = _b.sent();
                        if (!!data.length) return [3 /*break*/, 2];
                        this.events.publish('widgets:publishnoProductsForList');
                        return [3 /*break*/, 7];
                    case 2:
                        productsData = [];
                        index = 0;
                        _b.label = 3;
                    case 3:
                        if (!(index < data.length)) return [3 /*break*/, 6];
                        obj = {};
                        obj.id = data[index].id;
                        _a = obj;
                        return [4 /*yield*/, this.angularFirestore.collection('products').doc(data[index].id).valueChanges().pipe(first()).toPromise()];
                    case 4:
                        _a.data = _b.sent();
                        productsData.push(obj);
                        _b.label = 5;
                    case 5:
                        index++;
                        return [3 /*break*/, 3];
                    case 6: 
                    //this.events.publish('widgets:publishProductsForList', productsData);
                    return [2 /*return*/, productsData];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    /************** Product Carousel*********************/
    /************** banner*********************/
    WidgetsService.prototype.getWebImagesForBanner = function (ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.widgetRefrence.doc(ID).collection('webSlides', function (ref) { return ref.orderBy('createdAt', 'desc').where('active', '==', true); }).snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                    case 2:
                        error_3 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WidgetsService.prototype.getMobImagesForBanner = function (ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.widgetRefrence.doc(ID).collection('slides', function (ref) { return ref.orderBy('createdAt', 'desc').where('active', '==', true); }).snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                    case 2:
                        error_4 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /************** banner*********************/
    WidgetsService.prototype.getWidgetData = function (ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var data, error_5;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.angularFirestore.collection('widgets').doc(ID).valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    console.log('getWidgetData', data);
                                    resolve(data);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_5 = _a.sent();
                                    console.dir(error_5);
                                    error_5['location'] = 'widgets-service:getWidgetData';
                                    this.logglyService.log(error_5);
                                    resolve({});
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.getCBSDataFromId = function (id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.angularFirestore.collection(type).doc(id).valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    resolve(data || null);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.bannerLink = function (bannerData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId, linkType, id, name_1, idLength, options, cname, brandname, res, servicName, userId_1, navigationExtras, productName, userId_2, category, pageName, userId_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!bannerData.hasOwnProperty('link')) return [3 /*break*/, 19];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        userId = _a.sent();
                        linkType = bannerData.link.type;
                        id = 'ids' in bannerData.link ? bannerData.link.ids : (typeof bannerData.link.id === 'string' ? [bannerData.link.id] : bannerData.link.id);
                        name_1 = bannerData.link.name || '';
                        idLength = id.length;
                        options = {
                            queryParams: {
                                values: JSON.stringify(id)
                            }
                        };
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['category'])) return [3 /*break*/, 2];
                        if (idLength > 1) {
                            options.queryParams['type'] = 'categories';
                            this.router.navigate(['tagged-items'], options);
                        }
                        else {
                            cname = encodeURL(name_1);
                            if (bannerData.link.hasOwnProperty('isSubcategories') && bannerData.link.isSubcategories) {
                                this.router.navigate(["shop-subcategories/" + cname + "/" + id[0]]);
                            }
                            else {
                                // const category: any = await this.getCBSDataFromId(id[0], 'categories');
                                // this.sharedService.manageExclusiveCategories(category.isExclusive || false, id[0], 'parentCategory');
                                this.router.navigate(["shop/category/" + cname + "/" + id[0]]);
                            }
                        }
                        return [3 /*break*/, 19];
                    case 2:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['brand'])) return [3 /*break*/, 3];
                        if (idLength > 1) {
                            options.queryParams['type'] = 'brands';
                            this.router.navigate(['tagged-items'], options);
                        }
                        else {
                            brandname = encodeURL(name_1);
                            this.router.navigate(["shop/brand/" + brandname + "/" + id[0]]);
                        }
                        return [3 /*break*/, 19];
                    case 3:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['service'])) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.sharedService.checkLoginStatus("" + this.labelService.labels['CO_CATEGORY_GRID']['must_be_user_to_access_services_msg'])];
                    case 4:
                        res = _a.sent();
                        if (res) {
                            servicName = encodeURL(name_1);
                            this.router.navigate(["service-response/" + servicName + "/" + id[0]]);
                        }
                        return [3 /*break*/, 19];
                    case 5:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['refer_and_earn'] || linkType === 'referEarn')) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 6:
                        userId_1 = _a.sent();
                        if (!userId_1) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['user-referral']);
                        }
                        return [3 /*break*/, 19];
                    case 7:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['search'])) return [3 /*break*/, 8];
                        navigationExtras = {
                            state: {
                                searchTxt: name_1
                            }
                        };
                        this.router.navigate(['search-items'], navigationExtras);
                        return [3 /*break*/, 19];
                    case 8:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['product'])) return [3 /*break*/, 9];
                        if (idLength > 1) {
                            options.queryParams['type'] = 'products';
                            this.router.navigate(['tagged-items'], options);
                        }
                        else {
                            productName = encodeURL(name_1);
                            this.router.navigate(["product-details/" + productName + "/" + id[0]]);
                        }
                        return [3 /*break*/, 19];
                    case 9:
                        if (!(linkType === this.labelService.labels['BANNER_SETTINGS']['membership'] || linkType === 'membership')) return [3 /*break*/, 11];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 10:
                        userId_2 = _a.sent();
                        if (!userId_2) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['membership']);
                        }
                        return [3 /*break*/, 19];
                    case 11:
                        if (!(linkType === 'subcategory')) return [3 /*break*/, 13];
                        return [4 /*yield*/, this.getCBSDataFromId(bannerData.link.categoryId, 'categories')];
                    case 12:
                        category = _a.sent();
                        // let exclusiveObj = await this.storage.get('exclusiveObj');
                        // const isExclusive = exclusiveObj.categoryType === 'parentCategory' && exclusiveObj.exclusivity ? false : category.isExclusive;
                        // this.sharedService.manageExclusiveCategories(isExclusive || false, bannerData.link.categoryId, 'subCategory');
                        this.router.navigate(["shop/category/" + this.getUrlTxt(category.name || '') + "/" + bannerData.link.categoryId + "/" + this.getUrlTxt(name_1 || '') + "/" + id[0]]);
                        return [3 /*break*/, 19];
                    case 13:
                        if (!(linkType === 'page')) return [3 /*break*/, 14];
                        pageName = encodeURL(name_1);
                        // this.router.navigate(['custom-page'], options)
                        this.router.navigate(["custom-page/" + pageName + "/" + id[0]]);
                        return [3 /*break*/, 19];
                    case 14:
                        if (!(linkType === 'external')) return [3 /*break*/, 15];
                        if (bannerData.link.url) {
                            window.open(bannerData.link.url, '_blank');
                        }
                        return [3 /*break*/, 19];
                    case 15:
                        if (!(linkType === 'contactUs')) return [3 /*break*/, 16];
                        this.router.navigate(['contact']);
                        return [3 /*break*/, 19];
                    case 16:
                        if (!(linkType === 'feedback')) return [3 /*break*/, 18];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 17:
                        userId_3 = _a.sent();
                        if (!userId_3) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['user-feedback']);
                        }
                        return [3 /*break*/, 19];
                    case 18:
                        if (linkType === 'offers') {
                            this.router.navigate(['all-offers']);
                        }
                        else if (linkType === 'pdf') {
                            if (bannerData.link.url) {
                                window.open(bannerData.link.url, '_blank');
                            }
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        _a.label = 19;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    WidgetsService.prototype.getUrlTxt = function (value) {
        return encodeURL(value);
    };
    WidgetsService.prototype.getMultipleTaggedItems = function (ids, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var documents, error_6;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.angularFirestore.collection(type, function (ref) { return ref
                                            .where(firebase.firestore.FieldPath.documentId(), 'in', ids); })
                                            .snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                            .pipe(first()).toPromise()];
                                case 1:
                                    documents = _a.sent();
                                    resolve(documents);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_6 = _a.sent();
                                    console.dir(error_6);
                                    error_6['location'] = 'widgets-service:getMultipleTaggedItems';
                                    this.logglyService.log(error_6);
                                    resolve([]);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.submitForm = function (formData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var _i, _a, key, storageRef, downloadURL, error_7;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 7, , 8]);
                                    _i = 0, _a = Object.keys(formData.formData);
                                    _b.label = 1;
                                case 1:
                                    if (!(_i < _a.length)) return [3 /*break*/, 5];
                                    key = _a[_i];
                                    if (!formData.formData[key].includes(';base64')) return [3 /*break*/, 4];
                                    storageRef = this.angularFireStorage.ref("forms/" + formData.userId + "/" + key);
                                    return [4 /*yield*/, storageRef.putString(formData.formData[key], 'data_url')];
                                case 2:
                                    _b.sent();
                                    return [4 /*yield*/, storageRef.getDownloadURL().pipe(first()).toPromise()];
                                case 3:
                                    downloadURL = _b.sent();
                                    formData.formData[key] = downloadURL;
                                    _b.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 5: return [4 /*yield*/, this.angularFirestore.collection('forms').add(formData)];
                                case 6:
                                    _b.sent();
                                    resolve(true);
                                    return [3 /*break*/, 8];
                                case 7:
                                    error_7 = _b.sent();
                                    console.dir(error_7);
                                    error_7['location'] = 'widgets-service:submitForm';
                                    this.logglyService.log(error_7);
                                    resolve(false);
                                    return [3 /*break*/, 8];
                                case 8: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.checkFormAlreadySubmitted = function (multipleSubmissions) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var userId_4, result, error_8;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 3, , 4]);
                                    return [4 /*yield*/, this.sharedService.getStorageUid()];
                                case 1:
                                    userId_4 = _a.sent();
                                    if (!userId_4) {
                                        resolve('no_login');
                                        return [2 /*return*/];
                                    }
                                    if (multipleSubmissions) {
                                        resolve('not_submitted');
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, this.angularFirestore.collection('forms', function (ref) { return ref.where('userId', '==', userId_4); }).valueChanges().pipe(first()).toPromise()];
                                case 2:
                                    result = _a.sent();
                                    resolve(!result.length ? 'not_submitted' : 'submitted');
                                    return [3 /*break*/, 4];
                                case 3:
                                    error_8 = _a.sent();
                                    console.dir(error_8);
                                    error_8['location'] = 'widgets-service:checkFormAlreadySubmitted';
                                    this.logglyService.log(error_8);
                                    resolve('error');
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.getVendors = function (vendorIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var vendors, region, _i, vendorIds_1, vendorId, vendor, finalVendors, error_9;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 9, , 10]);
                                    vendors = [];
                                    return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                                case 1:
                                    region = _a.sent();
                                    _i = 0, vendorIds_1 = vendorIds;
                                    _a.label = 2;
                                case 2:
                                    if (!(_i < vendorIds_1.length)) return [3 /*break*/, 5];
                                    vendorId = vendorIds_1[_i];
                                    return [4 /*yield*/, this.angularFirestore.collection('features').doc('multiVendor').collection('vendors').doc(vendorId)
                                            .valueChanges().pipe(first()).toPromise()];
                                case 3:
                                    vendor = _a.sent();
                                    if (vendor) {
                                        if (region.regionId) {
                                            if (region.regionId === (vendor.regionId || '')) {
                                                vendors.push(tslib_1.__assign({ id: vendorId }, vendor));
                                            }
                                        }
                                        else {
                                            vendors.push(tslib_1.__assign({ id: vendorId }, vendor));
                                        }
                                    }
                                    _a.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 2];
                                case 5:
                                    if (!this.vendorService.vendorSettings.vendorBasedOnKm) return [3 /*break*/, 7];
                                    return [4 /*yield*/, this.getVendorsBasedOnDistance(vendors)];
                                case 6:
                                    finalVendors = _a.sent();
                                    this.vendorService.vendorSettings.vendors = finalVendors;
                                    resolve(finalVendors);
                                    return [3 /*break*/, 8];
                                case 7:
                                    this.vendorService.vendorSettings.vendors = vendors;
                                    resolve(vendors);
                                    _a.label = 8;
                                case 8: return [3 /*break*/, 10];
                                case 9:
                                    error_9 = _a.sent();
                                    console.log('error', error_9);
                                    return [3 /*break*/, 10];
                                case 10: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.getUserLocation = function () {
        return { lat: this.vendorService.userPlace.lat, lng: this.vendorService.userPlace.lng };
    };
    WidgetsService.prototype.makeArrayGroups = function (array, groupSize) {
        var result = [];
        for (var index = 0; index < array.length; index += groupSize) {
            var group = array.slice(index, index + groupSize);
            result.push(group);
        }
        console.log('result', result);
        return result;
    };
    WidgetsService.prototype.getVendorsBasedOnDistance = function (vendors) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this_1, void 0, void 0, function () {
                        var vendorGroups, userLocation, origins, destinations, distances, _i, vendorGroups_1, vendorGroup, _a, vendorGroup_1, vendor, oneGroupDistances, _b, oneGroupDistances_1, d, _c, vendorGroups_2, vendorGroup, _loop_1, _d, vendorGroup_2, vendor, allVendors, vendors_1;
                        return tslib_1.__generator(this, function (_e) {
                            switch (_e.label) {
                                case 0:
                                    vendorGroups = this.makeArrayGroups(vendors, 25);
                                    userLocation = this.getUserLocation();
                                    if (!(!userLocation.lat || !userLocation.lng)) return [3 /*break*/, 1];
                                    resolve([]);
                                    return [3 /*break*/, 6];
                                case 1:
                                    origins = [new google.maps.LatLng(userLocation.lat, userLocation.lng)];
                                    destinations = [];
                                    distances = [];
                                    _i = 0, vendorGroups_1 = vendorGroups;
                                    _e.label = 2;
                                case 2:
                                    if (!(_i < vendorGroups_1.length)) return [3 /*break*/, 5];
                                    vendorGroup = vendorGroups_1[_i];
                                    for (_a = 0, vendorGroup_1 = vendorGroup; _a < vendorGroup_1.length; _a++) {
                                        vendor = vendorGroup_1[_a];
                                        if (vendor && vendor.vendorAddress && vendor.vendorAddress.lat && vendor.vendorAddress.lng) {
                                            destinations.push({ id: vendor.id, latLng: new google.maps.LatLng(vendor.vendorAddress.lat, vendor.vendorAddress.lng) });
                                        }
                                    }
                                    return [4 /*yield*/, this.distanceApi(origins, destinations)];
                                case 3:
                                    oneGroupDistances = _e.sent();
                                    for (_b = 0, oneGroupDistances_1 = oneGroupDistances; _b < oneGroupDistances_1.length; _b++) {
                                        d = oneGroupDistances_1[_b];
                                        distances.push(d);
                                    }
                                    _e.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 2];
                                case 5:
                                    for (_c = 0, vendorGroups_2 = vendorGroups; _c < vendorGroups_2.length; _c++) {
                                        vendorGroup = vendorGroups_2[_c];
                                        _loop_1 = function (vendor) {
                                            var index = distances.findIndex(function (d) { return d.id === vendor.id; });
                                            if (index !== -1) {
                                                vendor['distance'] = distances[index].distance;
                                            }
                                            else {
                                                vendor['distance'] = 0;
                                            }
                                        };
                                        for (_d = 0, vendorGroup_2 = vendorGroup; _d < vendorGroup_2.length; _d++) {
                                            vendor = vendorGroup_2[_d];
                                            _loop_1(vendor);
                                        }
                                    }
                                    allVendors = vendorGroups.flat(1);
                                    vendors_1 = [];
                                    allVendors.forEach(function (vendor) {
                                        if (vendor.distance) {
                                            vendors_1.push(vendor);
                                        }
                                    });
                                    vendors_1.sort(function (a, b) {
                                        return a.distance - b.distance;
                                    });
                                    console.log('vendors', vendors_1);
                                    resolve(vendors_1);
                                    _e.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    WidgetsService.prototype.distanceApi = function (origins, destinations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var matrix, _this;
            return tslib_1.__generator(this, function (_a) {
                matrix = new google.maps.DistanceMatrixService();
                _this = this;
                return [2 /*return*/, new Promise(function (resolve) {
                        var destinationsLatLng = [];
                        for (var _i = 0, destinations_1 = destinations; _i < destinations_1.length; _i++) {
                            var destination = destinations_1[_i];
                            destinationsLatLng.push(destination.latLng);
                        }
                        matrix.getDistanceMatrix({
                            origins: origins,
                            destinations: destinationsLatLng,
                            travelMode: google.maps.TravelMode.DRIVING,
                        }, function (response, status) {
                            console.log('response', response);
                            if (status === 'OK') {
                                if (response.rows && response.rows.length && response.rows[0].elements) {
                                    var results = response.rows[0].elements;
                                    for (var index = 0; index < results.length; index++) {
                                        var distance = results[index].status === 'OK' && results[index].distance && results[index].distance.value ? results[index].distance.value / 1000 : 0;
                                        if (distance > _this.vendorService.vendorSettings.vendorDistance) {
                                            distance = 0;
                                        }
                                        destinations[index]['distance'] = distance;
                                    }
                                    resolve(destinations);
                                }
                                else {
                                    resolve([]);
                                }
                            }
                            else {
                                resolve([]);
                            }
                        });
                    })];
            });
        });
    };
    WidgetsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WidgetsService_Factory() { return new WidgetsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LabelService), i0.ɵɵinject(i4.SharedService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i6.UtilsService), i0.ɵɵinject(i7.LogglyLoggerService), i0.ɵɵinject(i8.AngularFireStorage), i0.ɵɵinject(i9.Storage), i0.ɵɵinject(i10.VendorService)); }, token: WidgetsService, providedIn: "root" });
    return WidgetsService;
}());
export { WidgetsService };
