import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { ModalController, AlertController, NavController, LoadingController, ToastController, Events } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { LabelService } from '../label/label.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { ConfigService } from '../config/config.service';
import { convertSnaps } from '../db-utils';
import { first, map } from 'rxjs/operators';
import { MultiRegionService } from '../multi-region/multi-region.service';
import { UtilsService } from '../utils/utils.service';
import { VendorService } from '../vendor/vendor.service';
import { HomePage } from 'src/app/home/home.page';
import { SharedNewService } from '../shared-new/shared-new.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "@ionic-native/social-sharing/ngx/index";
import * as i4 from "../label/label.service";
import * as i5 from "../loggly-logger/loggly-logger.service";
import * as i6 from "@angular/fire/firestore";
import * as i7 from "../config/config.service";
import * as i8 from "../multi-region/multi-region.service";
import * as i9 from "../utils/utils.service";
import * as i10 from "../vendor/vendor.service";
import * as i11 from "../multi-countries/multi-countries.service";
import * as i12 from "../shared-new/shared-new.service";
export class SharedService {
    constructor(modalController, storage, alertController, socialSharing, 
    //private firebaseDynamicLinks: FirebaseDynamicLinks,
    navCtrl, labelService, logglyService, afs, configService, events, 
    //private multiRegionService: MultiRegionService,
    loadingController, toastController, multiRegionService, utilsService, vendorService, multiCountriesService, sharedNewService) {
        this.modalController = modalController;
        this.storage = storage;
        this.alertController = alertController;
        this.socialSharing = socialSharing;
        this.navCtrl = navCtrl;
        this.labelService = labelService;
        this.logglyService = logglyService;
        this.afs = afs;
        this.configService = configService;
        this.events = events;
        this.loadingController = loadingController;
        this.toastController = toastController;
        this.multiRegionService = multiRegionService;
        this.utilsService = utilsService;
        this.vendorService = vendorService;
        this.multiCountriesService = multiCountriesService;
        this.sharedNewService = sharedNewService;
        this.referrer = { userId: '' };
        this.isPromoPopupViewed = false;
        this.sharedLinkRouted = false;
    }
    /* async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
         try {
             const modal = await this.modalController.create({
                 component: HomePage,
                 cssClass: 'custom-modal login-modal',
                 backdropDismiss: false,
                 showBackdrop: true,
                 componentProps: {
                     showIdentity: true,
                     showSignin: false,
                     showPincode: false,
                     showVerifyOtp: false,
                     showPhoneNo: false,
                     userId: uid,
                     btnTxt: 'Save',
                     userName: name,
                     userEmail: email,
                     phoneNo: phoneNo,
                     onlyIdentity: true
                 }
             });
             await modal.present();
         } catch (error) {
             error['location'] = 'shared-service:openLoginModalForIdentity';
             this.logglyService.log(error);
         }
 
     }*/
    // async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
    //     try {
    //         const modal = await this.modalController.create({
    //             component: HomePage,
    //             cssClass: 'custom-modal login-modal',
    //             backdropDismiss: false,
    //             showBackdrop: true,
    //             componentProps: {
    //                 showIdentity: true,
    //                 showSignin: false,
    //                 showPincode: false,
    //                 showVerifyOtp: false,
    //                 showPhoneNo: false,
    //                 userId: uid,
    //                 btnTxt: 'Save',
    //                 userName: name,
    //                 userEmail: email,
    //                 phoneNo: phoneNo,
    //                 onlyIdentity: true
    //             }
    //         });
    //         await modal.present();
    //     } catch (error) {
    //         error['location'] = 'shared-service:openLoginModalForIdentity';
    //         this.logglyService.log(error);
    //     }
    // }
    checkLoginStatus(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.getStorageUid();
            this.utilsService.consoleLog('check login uid', uid);
            if (!uid || uid === undefined) {
                this.utilsService.openLoginModal();
                //this.events.publish('app:openLoginModal');
            }
            else {
                const role = yield this.getStorageRole();
                if (role === 'admin') {
                    this.presentAlert(msg);
                }
                else if (role === 'deliveryAgent') {
                    this.presentAlert(msg);
                }
                else {
                    return true;
                }
            }
        });
    }
    socialShare(name, subject, img, link) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.socialSharing.share(name, subject, img, link).then(() => {
                    // console.log('app sharing works!');
                }).catch((err) => {
                    // console.log('app sharing not works!', err);
                });
            }
            catch (error) {
                error['location'] = 'shared-service:socialShare';
                this.logglyService.log(error);
            }
        });
    }
    handleProductSharing() {
        /* try {
             this.firebaseDynamicLinks.onDynamicLink()
                 .subscribe((res: any) => {
                     if (res.deepLink.includes('product-details')) {
                         this.navigateToProduct(res.deepLink);
                     } else {
                         this.setReferralObj(res.deepLink);
                     }
                 }), (error: any) => {
                     // console.log(error)
                     this.logglyService.log(error)
                 };
         } catch (error) {
             error['location'] = 'shared-service:handleProductSharing';
             this.logglyService.log(error);
         }*/
    }
    navigateToProduct(link) {
        try {
            // console.log('link', link);
            let pid = '';
            for (let index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                }
                else {
                    pid += link[index];
                }
            }
            pid = pid.split('').reverse().join('');
            // console.log('pid from dynamic link:', pid);
            this.sharedLinkRouted = true;
            const navigationExtras = {
                state: {
                    productId: pid
                }
            };
            this.navCtrl.navigateRoot(['product-details'], navigationExtras);
        }
        catch (error) {
            error['location'] = 'shared-service:navigateToProduct';
            this.logglyService.log(error);
        }
    }
    setReferralObj(link) {
        try {
            // console.log('link', link);
            let userId = '';
            for (let index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                }
                else {
                    userId += link[index];
                }
            }
            userId = userId.split('').reverse().join('');
            // console.log('userId from dynamic link:', userId);
            this.referrer.userId = userId;
        }
        catch (error) {
            error['location'] = 'shared-service:setReferralObj';
            this.logglyService.log(error);
        }
    }
    getStorageUid() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('uid').then((val) => {
                        resolve(val);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getStorageUid';
                this.logglyService.log(error);
            }
        });
    }
    getStorageRole() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('userRole').then((val) => {
                        resolve(val);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getStorageRole';
                this.logglyService.log(error);
            }
        });
    }
    getBannersImgPreloader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('bannersImgPreloader').then((value) => {
                        resolve(value);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getBannersImgPreloader';
                this.logglyService.log(error);
            }
        });
    }
    getCategoriesProductsImgsPreloader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('categoriesProductsImgPreloader').then((value) => {
                        resolve(value);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getCategoriesProductsImgsPreloader';
                this.logglyService.log(error);
            }
        });
    }
    getBrandsProductsImgsPreloader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('brandsProductsImgPreloader').then((value) => {
                        resolve(value);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getBrandsProductsImgsPreloader';
                this.logglyService.log(error);
            }
        });
    }
    getSubCategoriesImgsPreloader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('subcategoriesImgPreloader').then((value) => {
                        resolve(value);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getSubCategoriesImgsPreloader';
                this.logglyService.log(error);
            }
        });
    }
    getStorageDeliverySettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('deliverySettings').then((val) => {
                        resolve(val);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getStorageDeliverySettings';
                this.logglyService.log(error);
            }
        });
    }
    getStorageCheckPincode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('checkPincode').then((val) => {
                        resolve(val);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getStorageCheckPincode';
                this.logglyService.log(error);
            }
        });
    }
    getStorageRegion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.storage.get('region').then((region) => {
                        resolve(region);
                    });
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:getStorageRegionId';
                this.logglyService.log(error);
            }
        });
    }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: [`${this.labelService.labels['SHARED']['ok']}`]
            });
            yield alert.present();
        });
    }
    presentLoading(duration = null, msg = this.labelService.labels['SHARED']['please_wait']) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: msg,
                duration: duration,
            });
            yield this.loading.present();
        });
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: msg,
                duration: 2000
            });
            toast.present();
        });
    }
    checkLimitedTimeDeal(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data.hasOwnProperty('deal') && data.deal.isAllowed) {
                let discount = data.deal.discount;
                if ('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length) {
                    const uid = yield this.getStorageUid();
                    const index = data.deal.specificUsers.users.findIndex(u => u.id === uid);
                    if (index === -1) {
                        return { dealTime: null, discount: null };
                    }
                    else {
                        discount = data.deal.specificUsers.users[index].discount;
                    }
                }
                const currentTime = moment();
                const startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
                const startTime = moment(data.deal.start.time).format('HH:mm');
                const endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
                const endTime = moment(data.deal.end.time).format('HH:mm');
                const startDateTime = moment(`${startDate} ${startTime}`);
                const endDateTime = moment(`${endDate} ${endTime}`);
                if (moment(currentTime).isBetween(startDateTime, endDateTime)) {
                    //console.log('in between');
                    const diff = moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(currentTime, 'DD/MM/YYYY HH:mm:ss'));
                    const duration = moment.duration(diff);
                    //console.log('duration', duration);
                    //console.log('moment.utc(diff).format(":mm:ss")', moment.utc(diff).format(":mm:ss"));
                    const dealTime = Math.floor(duration.asHours()) + moment.utc(diff).format(":mm:ss");
                    //console.log('dealTime', dealTime);
                    return { dealTime, discount };
                }
                else {
                    //console.log('not between');
                    return { dealTime: null, discount: null };
                }
            }
            else {
                return { dealTime: null, discount: null };
            }
        });
    }
    getStarColor(rating) {
        if (rating >= 3) {
            return '#20c020';
        }
        else if (rating < 3 && rating >= 2) {
            return '#FF9F00';
        }
        else {
            return '#ff6161';
        }
    }
    getUserCartProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const uid = yield this.getStorageUid();
                    if (uid && uid !== undefined) {
                        const cartRef = this.afs.collection('users').doc(uid).collection('cart');
                        const cartProducts = yield cartRef.snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                        resolve(cartProducts);
                    }
                }
                catch (err) {
                    console.dir(err);
                    err['location'] = 'shared-service:getUserCartProducts';
                    this.logglyService.log(err);
                }
            }));
        });
    }
    checkRegionIdForApi() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    let region = { regionId: '', vendorId: '' };
                    const role = yield this.getStorageRole();
                    if (role !== 'admin') {
                        let isMultiRegion = this.configService.environment.multiRegion;
                        let isMultiVendor = this.configService.environment.multiVendor;
                        if (isMultiRegion) {
                            const dbMultiRegion = yield this.multiRegionService.getActiveStatus('service');
                            isMultiRegion = dbMultiRegion && dbMultiRegion.active;
                        }
                        if (isMultiRegion) {
                            const userRegion = yield this.getStorageRegion();
                            if (userRegion) {
                                region.regionId = userRegion.id;
                                // if(userRegion.vendorId) {
                                //     const vendorSettings: any = await this.vendorService.getActiveStatus('service');
                                //     if(isMultiVendor && vendorSettings && vendorSettings.active && vendorSettings.oneVendorPerRegion) {
                                //         const vendor: any = await this.vendorService.getVendorData(userRegion.vendorId);
                                //         region.vendorId = vendor.active ? userRegion.vendorId : 'inactive';
                                //     }
                                // }
                            }
                        }
                    }
                    resolve(region);
                }));
            }
            catch (error) {
                error['location'] = 'shared-service:checkRegionIdForApi';
                this.logglyService.log(error);
            }
        });
    }
    manageExclusiveCategories(isCategoryExclusive, categoryId, categoryType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let exclusiveObj = yield this.storage.get('exclusiveObj');
            if (!exclusiveObj || exclusiveObj === undefined) {
                exclusiveObj = { exclusivity: isCategoryExclusive || false, category: isCategoryExclusive || false, categoryId, categoryType };
            }
            else {
                exclusiveObj['categoryId'] = categoryId;
                exclusiveObj['categoryType'] = categoryType;
                if (isCategoryExclusive) {
                    exclusiveObj['category'] = true;
                }
                else {
                    exclusiveObj['category'] = false;
                }
            }
            // console.log('exclusiveObj in shared', exclusiveObj);
            this.storage.set('exclusiveObj', exclusiveObj);
        });
    }
    prepareProductData(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const wishlistProducts = data.wishlistProducts, subRole = data.subRole, userId = data.userId, cartProducts = data.cartProducts;
            let product = data.product;
            //console.log('data product', product);
            const cartIndex = cartProducts.findIndex(c => c.productId === product.id);
            if (userId !== '' && (cartIndex !== -1)) {
                product['cartQty'] = cartProducts[cartIndex].quantity;
                product['cartId'] = cartProducts[cartIndex].id;
            }
            else {
                product['cartQty'] = 0;
                product['cartId'] = '';
            }
            if (userId !== '' && wishlistProducts && wishlistProducts.length && wishlistProducts.indexOf(product.id) !== -1) {
                product['isWishlisted'] = true;
            }
            else {
                product['isWishlisted'] = false;
            }
            product = yield this.updateProductPrices(product, subRole);
            return product;
        });
    }
    updateProductPrices(product, subRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let deal = yield this.checkLimitedTimeDeal(product);
            if (deal.dealTime) {
                if (deal.discount > 0) {
                    product.dealActive = true;
                    if (!product.isPriceList) {
                        product.discountedPrice = product.prodPrice - (product.prodPrice * (deal.discount / 100));
                    }
                    else {
                        product.priceList.forEach(pl => {
                            pl.discountedPrice = pl.price - (pl.price * (deal.discount / 100));
                        });
                    }
                }
            }
            if (subRole && subRole === 'retailer' && this.configService.environment.priceForRetail) {
                let retailDiscount = product.retailDiscount ? product.retailDiscount : 0;
                let retailDiscountType = product.retailDiscountType || 'percentage';
                if (retailDiscount) {
                    if (!product.isPriceList) {
                        if (retailDiscountType === 'percentage') {
                            product.discountedPrice = product.prodPrice - (product.prodPrice * (retailDiscount / 100));
                        }
                        else {
                            product.discountedPrice = product.prodPrice - retailDiscount;
                        }
                    }
                    else {
                        product.priceList.forEach(pl => {
                            if (retailDiscountType === 'percentage') {
                                pl.discountedPrice = pl.price - (pl.price * (retailDiscount / 100));
                            }
                            else {
                                pl.discountedPrice = pl.price - retailDiscount;
                            }
                        });
                    }
                }
            }
            if (!product.isPriceList) {
                product.discountedPrice = this.getPriceBasedOnExhangeRate(product.discountedPrice);
                product.prodPrice = this.getPriceBasedOnExhangeRate(product.prodPrice);
            }
            else {
                product.priceList.forEach(pl => {
                    pl.discountedPrice = this.getPriceBasedOnExhangeRate(pl.discountedPrice);
                    pl.price = this.getPriceBasedOnExhangeRate(pl.price);
                });
            }
            return product;
        });
    }
    getPriceBasedOnExhangeRate(price) {
        return this.sharedNewService.getPriceBasedOnExhangeRate(price);
    }
    openLoginModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: HomePage,
                backdropDismiss: false,
                cssClass: "custom-modal login-modal",
            });
            if (this.configService.environment.appAccessOnApproval) {
                modal.onDidDismiss().then((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.presentLoading();
                    setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        this.loading.dismiss();
                        yield this.checkForAppAccesss();
                    }), 2000);
                }));
            }
            yield modal.present();
        });
    }
    checkForAppAccesss() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.getStorageUid();
            if (!uid || uid === undefined) {
                this.loginForAppAccessAlert();
            }
            else {
                const accessByAdmin = yield this.storage.get('accessByAdmin');
                // console.log('accessByAdmin', accessByAdmin);
                if (!accessByAdmin) {
                    this.accessNotAllowedAlert();
                }
            }
        });
    }
    loginForAppAccessAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: this.labelService.labels['SHARED']['login_to_continue'],
                backdropDismiss: false,
                buttons: [{
                        text: this.labelService.labels['SHARED']['login'],
                        handler: () => {
                            this.openLoginModal();
                        }
                    }]
            });
            yield alert.present();
        });
    }
    accessNotAllowedAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: this.labelService.labels['SHARED']['access_not_allowed_msg'],
                backdropDismiss: false
            });
            yield alert.present();
        });
    }
    getFaqData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // let data = JSON.parse(sessionStorage.getItem("faqPageData")) || {};
                let data = JSON.parse(localStorage.getItem("faqPageData") || " {}");
                if (!(Object.keys(data).length)) {
                    data = yield this.afs.collection('settings').doc('contactUs').valueChanges().pipe(first()).toPromise();
                    sessionStorage.setItem("faqPageData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
    presentAlertConfirm(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const alert = yield this.alertController.create({
                    message: msg,
                    buttons: [
                        {
                            text: this.labelService.labels['SHARED']['no'],
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                //console.log('Confirm Cancel: blah');
                                resolve(false);
                            }
                        }, {
                            text: this.labelService.labels['SHARED']['yes'],
                            handler: () => {
                                //console.log('Confirm Okay');
                                resolve(true);
                            }
                        }
                    ]
                });
                yield alert.present();
            }));
        });
    }
    dismissLoading() {
        this.loading.dismiss();
    }
    convert24to12(time) {
        return moment(time, ['HH:mm']).format('hh:mm A');
    }
}
SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i3.SocialSharing), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i4.LabelService), i0.ɵɵinject(i5.LogglyLoggerService), i0.ɵɵinject(i6.AngularFirestore), i0.ɵɵinject(i7.ConfigService), i0.ɵɵinject(i1.Events), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i8.MultiRegionService), i0.ɵɵinject(i9.UtilsService), i0.ɵɵinject(i10.VendorService), i0.ɵɵinject(i11.MultiCountriesService), i0.ɵɵinject(i12.SharedNewService)); }, token: SharedService, providedIn: "root" });
