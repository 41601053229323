import * as tslib_1 from "tslib";
import { AngularFirestore } from "@angular/fire/firestore";
import { Events } from "@ionic/angular";
import algoliasearch from "algoliasearch";
import { environment } from "src/environments/environment";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "../shared/shared.service";
import * as i4 from "../config/config.service";
export class SearchEngineService {
    constructor(afs, events, sharedService, configService) {
        this.afs = afs;
        this.events = events;
        this.sharedService = sharedService;
        this.configService = configService;
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.page = 0;
        this.products = [];
        this.adminProducts = [];
    }
    initializeSubscriptions() {
        this.events.subscribe("search-engine:alogoliaSearchProducts", (searchValue, page, type) => {
            this.alogoliaSearchProducts(searchValue, page, type);
        });
        this.events.subscribe("search-engine:getProductsForSearchResults", (searchValue, page, type) => {
            this.getProductsForSearchResults(searchValue, page, type);
        });
    }
    alogoliaSearchProducts(searchValue, page, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (type === "new_search") {
                    this.products = [];
                }
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let vendorId = region.vendorId;
                let filters = "status:true";
                if (regionId) {
                    filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
                }
                if (vendorId) {
                    filters += ` AND vendorId:${vendorId}`;
                }
                this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
                this.index = this.client.initIndex(this.APP_PROJECT_ID);
                this.index
                    .search(searchValue, { page: page, filters })
                    .then((result) => {
                    // console.log(result);
                    if (result.nbPages === 0) {
                        this.events.publish("search-engine:noSearchProductsAvailable");
                    }
                    else if (result.hits.length === 0 && page === result.nbPages) {
                        this.events.publish("search-engine:noMoreSearchProducts");
                    }
                    else {
                        result.hits.forEach((h) => {
                            this.products.push(Object.assign({}, h, { id: h.objectID }));
                        });
                        this.events.publish("search-engine:productSearchResults", this.products);
                    }
                })
                    .catch((err) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    //console.log(err);
                }));
                this.saveAnalytics(searchValue);
            }
            catch (error) {
                //console.log(error);
            }
        });
    }
    getProductsForSearchResults(searchValue, page, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (type === "new_search") {
                    this.products = [];
                }
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let vendorId = region.vendorId;
                let filters = "status:true";
                if (regionId) {
                    filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
                }
                if (vendorId) {
                    filters += ` AND vendorId:${vendorId}`;
                }
                this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
                this.index = this.client.initIndex(this.APP_PROJECT_ID);
                this.index
                    .search(searchValue, { page: page, filters })
                    .then((result) => {
                    // console.log(result);
                    if (result.nbPages === 0) {
                        this.events.publish("search-engine:noProductsForSearchResults");
                    }
                    else if (result.hits.length === 0 && page === result.nbPages) {
                        this.events.publish("search-engine:noMoreProductsForSearchResults");
                    }
                    else {
                        result.hits.forEach((h) => {
                            this.products.push(Object.assign({}, h, { id: h.objectID }));
                        });
                        this.events.publish("search-engine:publishProductForSearchResults", this.products);
                    }
                })
                    .catch((err) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    //console.log(err);
                }));
                this.saveAnalytics(searchValue);
            }
            catch (error) {
                //console.log(error);
            }
        });
    }
    saveAnalytics(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.sharedService.getStorageUid();
            if (uid) {
                const data = {
                    value,
                    searchedAt: new Date(),
                    source: "web",
                };
                this.afs
                    .collection("users")
                    .doc(uid)
                    .collection("analytics")
                    .doc("search")
                    .collection("data")
                    .add(data);
            }
        });
    }
}
SearchEngineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchEngineService_Factory() { return new SearchEngineService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.SharedService), i0.ɵɵinject(i4.ConfigService)); }, token: SearchEngineService, providedIn: "root" });
