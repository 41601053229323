import * as tslib_1 from "tslib";
import { AngularFirestore } from "@angular/fire/firestore";
import { Events } from "@ionic/angular";
import algoliasearch from "algoliasearch";
import { environment } from "src/environments/environment";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "../shared/shared.service";
import * as i4 from "../config/config.service";
var SearchEngineService = /** @class */ (function () {
    function SearchEngineService(afs, events, sharedService, configService) {
        this.afs = afs;
        this.events = events;
        this.sharedService = sharedService;
        this.configService = configService;
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.page = 0;
        this.products = [];
        this.adminProducts = [];
    }
    SearchEngineService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe("search-engine:alogoliaSearchProducts", function (searchValue, page, type) {
            _this.alogoliaSearchProducts(searchValue, page, type);
        });
        this.events.subscribe("search-engine:getProductsForSearchResults", function (searchValue, page, type) {
            _this.getProductsForSearchResults(searchValue, page, type);
        });
    };
    SearchEngineService.prototype.alogoliaSearchProducts = function (searchValue, page, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, regionId, vendorId, filters, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (type === "new_search") {
                            this.products = [];
                        }
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId = region.regionId;
                        vendorId = region.vendorId;
                        filters = "status:true";
                        if (regionId) {
                            filters += " AND (categoryRegions:" + regionId + " OR brandRegions:" + regionId + ")";
                        }
                        if (vendorId) {
                            filters += " AND vendorId:" + vendorId;
                        }
                        this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
                        this.index = this.client.initIndex(this.APP_PROJECT_ID);
                        this.index
                            .search(searchValue, { page: page, filters: filters })
                            .then(function (result) {
                            // console.log(result);
                            if (result.nbPages === 0) {
                                _this.events.publish("search-engine:noSearchProductsAvailable");
                            }
                            else if (result.hits.length === 0 && page === result.nbPages) {
                                _this.events.publish("search-engine:noMoreSearchProducts");
                            }
                            else {
                                result.hits.forEach(function (h) {
                                    _this.products.push(tslib_1.__assign({}, h, { id: h.objectID }));
                                });
                                _this.events.publish("search-engine:productSearchResults", _this.products);
                            }
                        })
                            .catch(function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                return [2 /*return*/];
                            });
                        }); });
                        this.saveAnalytics(searchValue);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SearchEngineService.prototype.getProductsForSearchResults = function (searchValue, page, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, regionId, vendorId, filters, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (type === "new_search") {
                            this.products = [];
                        }
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId = region.regionId;
                        vendorId = region.vendorId;
                        filters = "status:true";
                        if (regionId) {
                            filters += " AND (categoryRegions:" + regionId + " OR brandRegions:" + regionId + ")";
                        }
                        if (vendorId) {
                            filters += " AND vendorId:" + vendorId;
                        }
                        this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
                        this.index = this.client.initIndex(this.APP_PROJECT_ID);
                        this.index
                            .search(searchValue, { page: page, filters: filters })
                            .then(function (result) {
                            // console.log(result);
                            if (result.nbPages === 0) {
                                _this.events.publish("search-engine:noProductsForSearchResults");
                            }
                            else if (result.hits.length === 0 && page === result.nbPages) {
                                _this.events.publish("search-engine:noMoreProductsForSearchResults");
                            }
                            else {
                                result.hits.forEach(function (h) {
                                    _this.products.push(tslib_1.__assign({}, h, { id: h.objectID }));
                                });
                                _this.events.publish("search-engine:publishProductForSearchResults", _this.products);
                            }
                        })
                            .catch(function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                return [2 /*return*/];
                            });
                        }); });
                        this.saveAnalytics(searchValue);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SearchEngineService.prototype.saveAnalytics = function (value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        if (uid) {
                            data = {
                                value: value,
                                searchedAt: new Date(),
                                source: "web",
                            };
                            this.afs
                                .collection("users")
                                .doc(uid)
                                .collection("analytics")
                                .doc("search")
                                .collection("data")
                                .add(data);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchEngineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchEngineService_Factory() { return new SearchEngineService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.SharedService), i0.ɵɵinject(i4.ConfigService)); }, token: SearchEngineService, providedIn: "root" });
    return SearchEngineService;
}());
export { SearchEngineService };
