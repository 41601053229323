import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Events } from "@ionic/angular";
import algoliasearch from "algoliasearch";
import { environment } from "src/environments/environment";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../config/config.service";
@Injectable({
  providedIn: "root",
})
export class SearchEngineService {
  client: any;
  index: any;
  ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
  ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
  APP_PROJECT_ID = environment.firebase.projectId;
  page: number = 0;
  products: any = [];
  adminProducts: any = [];
  constructor(
    private afs: AngularFirestore,
    private events: Events,
    private sharedService: SharedService,
    private configService: ConfigService
  ) {}
  initializeSubscriptions() {
    this.events.subscribe(
      "search-engine:alogoliaSearchProducts",
      (searchValue, page, type) => {
        this.alogoliaSearchProducts(searchValue, page, type);
      }
    );

    this.events.subscribe(
      "search-engine:getProductsForSearchResults",
      (searchValue, page, type) => {
        this.getProductsForSearchResults(searchValue, page, type);
      }
    );
  }

  async alogoliaSearchProducts(
    searchValue: string,
    page: number,
    type: string
  ) {
    try {
      if (type === "new_search") {
        this.products = [];
      }
      const region = await this.sharedService.checkRegionIdForApi();
      let regionId = region.regionId;
      let vendorId = region.vendorId;
      let filters = "status:true";
      if (regionId) {
        filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
      }
      if (vendorId) {
        filters += ` AND vendorId:${vendorId}`;
      }

      this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
      this.index = this.client.initIndex(this.APP_PROJECT_ID);
      this.index
        .search(searchValue, { page: page, filters })
        .then((result) => {
          // console.log(result);
          if (result.nbPages === 0) {
            this.events.publish("search-engine:noSearchProductsAvailable");
          } else if (result.hits.length === 0 && page === result.nbPages) {
            this.events.publish("search-engine:noMoreSearchProducts");
          } else {
            result.hits.forEach((h) => {
              this.products.push({...h, id: h.objectID });
            });
            this.events.publish(
              "search-engine:productSearchResults",
              this.products
            );
          }
        })
        .catch(async (err) => {
          //console.log(err);
        });

      this.saveAnalytics(searchValue);
    } catch (error) {
      //console.log(error);
    }
  }


  async getProductsForSearchResults(
    searchValue: string,
    page: number,
    type: string
  ) {
    try {
      if (type === "new_search") {
        this.products = [];
      }
      const region = await this.sharedService.checkRegionIdForApi();
      let regionId = region.regionId;
      let vendorId = region.vendorId;
      let filters = "status:true";
      if (regionId) {
        filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
      }
      if (vendorId) {
        filters += ` AND vendorId:${vendorId}`;
      }

      this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
      this.index = this.client.initIndex(this.APP_PROJECT_ID);
      this.index
        .search(searchValue, { page: page, filters })
        .then((result) => {
          // console.log(result);
          if (result.nbPages === 0) {
            this.events.publish("search-engine:noProductsForSearchResults");
          } else if (result.hits.length === 0 && page === result.nbPages) {
            this.events.publish("search-engine:noMoreProductsForSearchResults");
          } else {
            result.hits.forEach((h) => {
              this.products.push({...h, id: h.objectID });
            });
            this.events.publish(
              "search-engine:publishProductForSearchResults",
              this.products
            );
          }
        })
        .catch(async (err) => {
          //console.log(err);
        });

      this.saveAnalytics(searchValue);
    } catch (error) {
      //console.log(error);
    }
  }

  async saveAnalytics(value: string) {
    const uid = await this.sharedService.getStorageUid();
    if (uid) {
      const data = {
        value,
        searchedAt: new Date(),
        source: "web",
      };
      this.afs
        .collection("users")
        .doc(uid)
        .collection("analytics")
        .doc("search")
        .collection("data")
        .add(data);
    }
  }
}
