import { Component, ViewChild, NgZone } from "@angular/core";
import {
  Events,
  AlertController,
  ToastController,
  IonRouterOutlet,
  Platform,
  ModalController,
  MenuController,
  LoadingController,
} from "@ionic/angular";
import { UserService } from "../services/user/user.service";
import * as firebase from "firebase/app";
import { Storage } from "@ionic/storage";
import { ConfigService } from "../services/config/config.service";
import { LabelService } from "src/app/services/label/label.service";
import { AdminSettingsService } from "../services/admin-settings/admin-settings.service";
import { TranslateService } from "@ngx-translate/core";
import { MultiCountriesService } from "../services/multi-countries/multi-countries.service";

@Component({
  selector: "app-home",
  templateUrl: "home.page.html",
  styleUrls: ["home.page.scss"],
})
export class HomePage {
  phoneNo: number = null;
  verificationID = "";
  backButtonSubscription: any;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  error = false;
  errorMsg;
  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  confirmationResult;
  verificationCode;
  user;
  mode = "mobile";
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
  loading: any;
  loadingTxt: string;
  isLoading: boolean = false;
  showNameInput: boolean = false;
  userName: string = "";
  userEmail: string = "";
  showPincode = false;
  storeData: any = {
    allowComment: false,
    allowStoreInfo: false,
    appPackageName: "",
    appVersion: "",
    commentMsg: "",
    facebookUrl: "",
    instagramUrl: "",
    storeAddress: {
      address: "",
      city: "",
      state: "",
      stateCode: "",
    },
    storeInfo: "",
    storeName: "storeName",
    storePhone: "",
    twitterUrl: "",
    welcomeMsg: "",
    youtubeUrl: "",
  };
  newUser = false;
  socialSigin = false;
  uid: any;
  phoneLength: number = this.configService.environment.phoneLength;
  onlyIdentity = false;
  showSignin = false;
  notDeliverable = false;
  pincode = "";
  maxDate = new Date().toISOString();
  birthday = "";
  HOME_LABELS: any = {};
  SHARED_LABELS: any = {};
  loginPopupSettings = {
    name: true,
    email: true,
    dob: true,
    gst: false,
    custom: {
      active: false,
      name: "",
    },
  };
  taxType = "";
  userGst = "";
  customInput = "";
  showResendBtn: boolean = false;
  timer: number;
  showTimer: boolean = true;
  countResendBtnClicked: number = 0;
  isTooManyOtp = false;
  telInputOptions = {
    autoHideDialCode: false,
    initialCountry: "in",
    separateDialCode: true,
    onlyCountries: ["in"],
    preferredCountries: ["in"],
  };
  isNumberValid = false;
  defaultCountryCode = "";
  constructor(
    private events: Events,
    private alertController: AlertController,
    private toastController: ToastController,
    private modalController: ModalController,
    private menuController: MenuController,
    //private platform: Platform,
    private configService: ConfigService,
    private ngZone: NgZone,
    private loadingController: LoadingController,
    private storage: Storage,
    private labelService: LabelService,
    private userService: UserService,
    private translateService: TranslateService,
    private adminSettingsService: AdminSettingsService,
    private multiCountriesService: MultiCountriesService
  ) {

  }

  async ngOnInit() {
    this.setCountriesOptions();
    const deliverySettings: any = await this.storage.get("deliverySettings");
    if (deliverySettings) {
      const isAllowAllPincodes = deliverySettings.isAllowAllPincodes;
      const checkPincode = await this.storage.get("checkPincode");
      const region = await this.storage.get("region");

      if (
        (!checkPincode || checkPincode === undefined) &&
        !isAllowAllPincodes &&
        (!region || region === undefined)
      ) {
        this.showPincode = true;
      } else {
        this.showSignin = true;
      }
    }

    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        badge: "bottomleft",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      }
    );
    this.recaptchaVerifier.render();
  }

  async ionViewWillEnter() {
    this.socialSigin = this.configService.environment.socialSigin;
    this.initializeSubscriptions();
    this.SHARED_LABELS = this.labelService.labels["SHARED"];
    this.HOME_LABELS = this.labelService.labels["HOME"];
    this.taxType = this.configService.environment.taxType || "GST";
    const storeSettings = await this.adminSettingsService.getSettingsData();
    if (storeSettings) {
      this.loginPopupSettings =
        storeSettings &&
        storeSettings.loginPopup &&
        Object.keys(storeSettings.loginPopup).length
          ? storeSettings.loginPopup
          : this.loginPopupSettings;
    }
    this.defaultCountryCode = this.configService.environment.defaultCountryCode;
  }
  ionViewWillLeave() {
    this.removeSubscriptions();
  }

  initializeSubscriptions() {
    this.storage.get("storeInfo").then((result) => {
      this.storeData = result;
    });

    this.events.subscribe("user:userAlreadyExists", async () => {
      this.isLoading = false;
      await this.multiCountriesService.setDefaultCountry();
      this.modalDismiss();
      window.location.reload();
      this.menuController.close();
    });
    this.events.subscribe(
      "user:userCreatedSuccessfully",
      async (uid, name, email) => {
        this.isLoading = false;
        this.uid = uid;
        this.storage.set("uid", uid);
        this.userName = name === "user" ? "" : name;
        this.userEmail = email;
        this.showNameInput = true;
        await this.multiCountriesService.setDefaultCountry();
      }
    );
    this.events.subscribe("user:updateNameOfNewUserSuccess", async () => {
      this.isLoading = false;
      this.modalDismiss();
      window.location.reload();
      this.menuController.close();
    });
    this.events.subscribe("auth:phoneAlreadyInUse", (mode) => {
      if (mode === "otp" || !mode) {
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["sending_otp"];
        this.sendOtp();
      }
      if (mode === "facebook") {
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["signing_in"];
        this.events.publish(
          "auth:fbLogin",
          this.defaultCountryCode + this.phoneNo
        );
      }
      if (mode === "google") {
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["signing_in"];
        this.events.publish(
          "auth:googleLogin",
          this.defaultCountryCode + this.phoneNo
        );
      }
    });
    this.events.subscribe("auth:newUser", () => {
      if (this.socialSigin) {
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["submit"];
        this.newUser = true;
      } else {
        this.sendOtp();
      }
    });

    this.events.subscribe("delivery-settings:pincodeDeliverable", () => {
      //this.btnTxt = this.HOME_LABELS['submit'];
      this.showPincode = false;
      this.showSignin = true;
      this.storage.set("checkPincode", true);
    });

    this.events.subscribe("delivery-settings:pincodeNotDeliverable", () => {
      //this.btnTxt = this.HOME_LABELS['submit'];
      this.notDeliverable = true;
    });
  }
  checkPincode() {
    this.events.publish("delivery-settings:checkPincode", this.pincode);
  }

  skipPincodeCheck() {
    //this.btnTxt = this.HOME_LABELS['submit'];
    this.showPincode = false;
    this.showSignin = true;
    this.notDeliverable = false;
  }

  pincodeChange() {
    this.notDeliverable = false;
  }

  signIn() {
    if(!this.isNumberValid) {
        this.presentAlert('Please enter a valid phone number');
        return;
    }
    this.isLoading = true;
    this.loadingTxt = this.HOME_LABELS["verifying"];
    console.log(
      "this.defaultCountryCode + this.phoneNo",
      this.defaultCountryCode + this.phoneNo
    );
    this.events.publish(
      "auth:checkUserStatus",
      this.defaultCountryCode + this.phoneNo
    );
  }

  reSendOtp() {
    this.showResendBtn = false;

    this.sendOtp();
  }

  resendCodeTimer() {
    this.timer = 60;
    const interval = setInterval(() => {
      this.timer -= 1;
      if (this.timer === 0) {
        clearInterval(interval);
        this.showTimer = false;
        this.showResendBtn = true;
      }
    }, 1000);
  }

  sendOtp() {
    this.resendCodeTimer();
    this.newUser = false;
    this.isLoading = true;
    const appVerifier = this.recaptchaVerifier;
    const phoneNumberString = this.defaultCountryCode + this.phoneNo;
    console.log("phoneNumberString", phoneNumberString);
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumberString, appVerifier)
      .then((result) => {
        this.ngZone.run(() => {
          // console.log(result);
          this.confirmationResult = result;
          this.mode = "otp";
          this.isLoading = false;
        });
      })
      .catch((error) => {
        this.ngZone.run(() => {
          this.error = true;
          this.isLoading = false;
          if (
            error ===
            "We have blocked all requests from this device due to unusual activity. Try again later."
          ) {
            this.isTooManyOtp = true;
            this.errorMsg =
              "Invalid Phone Number. Please check your phone number and try again.";
          }
          this.errorMsg = "Too many attempts. try again later";
        });
      });
  }

  verifyLoginCode() {
    this.isLoading = true;
    this.loadingTxt = this.HOME_LABELS["verifying"];
    this.confirmationResult
      .confirm(String(this.verificationCode))
      .then(async (result) => {
        this.user = result.user;
        this.uid = this.user.uid;

        this.userService.checkUserAlreadyExistsOrNot(result.user.uid);
      })
      .catch((error) => {
        this.error = true;
        this.errorMsg = "Incorrect OTP entered";
        this.isLoading = false;
        this.loadingTxt = this.HOME_LABELS["login"];
      });
  }

  checkError() {
    this.error = false;
  }

  googleLogin() {
    this.newUser = false;
    this.events.publish(
      "auth:googleLogin",
      this.defaultCountryCode + this.phoneNo
    );
  }

  fbLogin() {
    this.newUser = false;
    this.events.publish("auth:fbLogin", this.defaultCountryCode + this.phoneNo);
  }

  updateUser() {
    //// console.log('username:', this.userName);
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.userEmail && !this.userEmail.match(mailFormat)) {
      this.presentAlert(
        this.translateService.instant("HOME.enter_valid_email")
      );
      return;
    }
    if (this.userName) {
      this.isLoading = true;
      this.loadingTxt = this.HOME_LABELS["saving"];
      this.events.publish(
        "user:updateNameOfNewUser",
        {
          name: this.userName,
          email: this.userEmail,
          birthday: this.birthday,
          lowercaseName: this.userName.toLowerCase(),
          customerGstNo: this.userGst,
          customInput: this.customInput,
        },
        this.uid
      );
    } else {
      this.presentAlert("Please Enter Name");
    }
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      color: "medium",
      message: msg,
      duration: 2000,
      showCloseButton: true,
      cssClass: "toast",
      animated: true,
    });
    toast.present();
  }
  async presentAlert(desc: string) {
    const alert = await this.alertController.create({
      message: desc,
      buttons: [
        {
          text: "Ok",
          handler: () => {
            //// console.log('Confirm Okay');
          },
        },
      ],
    });
    await alert.present();
  }

  async presentLoading(msg, drtn) {
    this.loading = await this.loadingController.create({
      message: msg,
      duration: drtn,
    });
    await this.loading.present();
  }

  modalDismiss() {
    this.modalController.dismiss();
    // document.querySelector(".scroll").animate(
    //   [
    //     // keyframes
    //     { transform: "translateX(0)" },
    //     { transform: "translateX(100%)" },
    //   ],
    //   {
    //     // timing options
    //     duration: 100,
    //     // iterations: Infinity
    //   }
    // );

    // setTimeout(() => {

    // }, 0);
  }

  async setCountriesOptions() {
    const countries = [];
    this.multiCountriesService.multiCountriesSettings.countries.forEach(
      (country) => {
        countries.push(country.countryCode);
      }
    );
    console.log("countries", countries);
    this.telInputOptions.onlyCountries = countries;
    // this.telInputOptions.initialCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry.countryCode;
    this.telInputOptions.initialCountry = (
      await this.multiCountriesService.getDefaultCountry()
    ).countryCode;
  }

  onCountryChange(e) {
    console.log(e);
    this.defaultCountryCode = `+${e.dialCode}`;
    this.multiCountriesService.countryInLogin =
      this.multiCountriesService.multiCountriesSettings.countries.find(
        (c) => c.dialCode === this.defaultCountryCode
      );
  }

  getNumber(e) {
    console.log(e);
  }

  telInputObject(e) {
    console.log(e);
  }

  hasError(e) {
    console.log(e);
    this.isNumberValid = e;
  }

  phoneChange() {
    this.isTooManyOtp = false;
    this.newUser = false;
    this.isNumberValid = false;
  }

  removeSubscriptions() {
    this.events.unsubscribe("user:userAlreadyExist");
    this.events.unsubscribe("user:userCreatedSuccessfully");
    this.events.unsubscribe("user:updateNameOfNewUserSuccess");
    this.events.unsubscribe("auth:phoneAlreadyInUse");
    this.events.unsubscribe("auth:newUser");
    this.events.unsubscribe("delivery-settings:pincodeDeliverable");
    this.events.unsubscribe("delivery-settings:pincodeNotDeliverable");
  }

  phoneNumberValidation(event: any, type: string) {
	let inputChar = String.fromCharCode(event.charCode);

	if (type === 'phone') {
		const pattern = /[0-9.,+]/;
		if (!pattern.test(inputChar) || String(this.phoneNo).length >= 10) {
			event.preventDefault();
		}
	} else if (type === 'otp') {
		const pattern = /[0-9]/;
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}
  }
}
